<template>
    <div class="d-flex flex-column flex-lg-row flex-column-fluid app-blank">
        <div class="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
            <div class="d-flex flex-center flex-column flex-lg-row-fluid">
                <div class="w-lg-500px p-10">
                    <form id="f" class="form w-100 requires-validation" v-on:submit.prevent="submit" novalidate>
                        <div class="text-center mb-15">
                            <h1 class="text-gray-700 fw-bolder mb-3">Logowanie</h1>

                            <div class="text-gray-500 fw-semibold fs-6">PartsExpert</div>
                        </div>

                        <div class="fv-row mb-4">
                            <input class="form-control form-control-solid border-0" type="email" placeholder="Adres e-mail" autocomplete="off" v-model="email" required />
                        </div>

                        <div class="fv-row mb-4">
                            <input class="form-control form-control-solid border-0" type="password" placeholder="Hasło" autocomplete="off" v-model="password" required />
                        </div>

                        <div class="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-4">
                            <div></div>

                            <div>
                                <router-link class="link-primary" v-bind:to="{ name: 'RecoveryPassword' }">Zapomniałeś hasła?</router-link>
                            </div>
                        </div>

                        <div class="d-grid mb-5">
                            <button type="submit" class="btn btn-primary">
                                <span class="indicator-label">Zaloguj</span>
                            </button>
                        </div>

                        <div class="text-gray-500 text-center fw-semibold">
                            Nie masz konta? <router-link class="link-primary" v-bind:to="{ name: 'SignUp' }">Zarejestruj się.</router-link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                email:    '',
                password: '',
            }
        },

        mounted() {
            document.body.setAttribute('data-kt-app-page-loading', 'off');
        },

        methods: {
            async submit(event) {
                if (!event.target.checkValidity()) {
                    event.target.classList.add('was-validated');

                    return;
                }

                document.body.setAttribute('data-kt-app-page-loading', 'on');

                try {
                    const response = await fetch(this.$API_HOST + '/authentication/sign-in', {
                        method: 'POST',
                        mode:   'cors',

                        headers: {
                            'Content-Type': 'application/json'
                        },

                        body: JSON.stringify({
                            'email':    this.email,
                            'password': this.password
                        })
                    });

                    setTimeout(() => {
                        document.body.setAttribute('data-kt-app-page-loading', 'off');
                    }, 250);

                    if (response.status === 401) {
                        alert('Nieprawidłowy adres e-mail lub hasło');
                    }

                    const data = (await response.json()).data;

                    window.localStorage
                        .setItem('token', data.token);

                    window.localStorage
                        .setItem('user', JSON.stringify({
                            'id':    data.id,
                            'name':  data.name,
                            'email': data.email,
                            'token': data.token,
                        }));

                    window.location = '/';
                } catch (error) {
                }
            }
        }
    }
</script>
