import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'

import ApplicationView from './view/Application'

// Layouts
import Account from '@/layout/Account'
import Authentication from '@/layout/Authentication'
import Home from '@/layout/Home'
import Part from '@/layout/Part'

import AdministrationLayout from '@/layout/AdministrationLayout'
import AdministrationUserLayout from '@/layout/administration/UserLayout'

import PaymentLayout from '@/layout/PaymentLayout'

// Account
import AccountOrderListView from '@/view/account/order/ListView'
import AccountOrderShowView from '@/view/account/order/ShowView'
import AccountPartListView from '@/view/account/part/ListView'

import AccountProfileEditView from '@/view/account/profile/EditView'

// Administration
import AdministrationOrderListView from '@/view/administration/order/ListView'
import AdministrationProductListView from '@/view/administration/product/ListView'
import AdministrationUserListView from '@/view/administration/user/ListView'
import AdministrationUserEditView from '@/view/administration/user/EditView'

// Authentication
import RecoveryPasswordView from '@/view/authentication/recovery/PasswordView.vue'
import SignInView from '@/view/authentication/SignInView'
import SignOutView from '@/view/authentication/SignOutView'
import SignUpView from '@/view/authentication/SignUpView'

// Cart
import CartListView from '@/view/cart/ListView'

// Payment
import PaymentRedirectView from '@/view/payment/RedirectView'

// Products
import PartListView from '@/view/part/ListView'
import PartNewView from '@/view/part/NewView'
import PartShowView from '@/view/part/ShowView'
import PartEditView from '@/view/part/EditView'

// Estimates
import Estimate from '@/layout/Estimate'
import EstimateListView from '@/view/estimate/ListView'

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),

    routes: [
        {
            path:      '/',
            name:      'Home',
            component: Home,

            redirect: {
                name: 'PartList'
            },

            children: [
                {
                    path:      'account',
                    component: Account,

                    children: [
                        {
                            path:      'products',
                            name:      'AccountPartList',
                            component: AccountPartListView
                        },

                        {
                            path:      'orders',
                            name:      'AccountOrderList',
                            component: AccountOrderListView
                        },

                        {
                            path:      'order/:id',
                            name:      'AccountOrderShow',
                            component: AccountOrderShowView
                        },

                        {
                            path:      'profile',
                            name:      'AccountProfileEdit',
                            component: AccountProfileEditView
                        }
                    ]
                },

                {
                    path:      'administration',
                    component: AdministrationLayout,

                    children: [
                        {
                            path:      'orders',
                            name:      'AdministrationOrderList',
                            component: AdministrationOrderListView
                        },

                        {
                            path:      'products',
                            name:      'AdministrationProductList',
                            component: AdministrationProductListView
                        },

                        {
                            path:      'users',
                            component: AdministrationUserLayout,

                            children: [
                                {
                                    path:      '',
                                    name:      'AdministrationUserList',
                                    component: AdministrationUserListView
                                },

                                {
                                    path:      'edit/:id',
                                    name:      'AdministrationUserEdit',
                                    component: AdministrationUserEditView
                                },
                            ]
                        }
                    ]
                },

                {
                    path:      'estimates',
                    component: Estimate,

                    children: [
                        {
                            path:      '',
                            name:      'EstimateList',
                            component: EstimateListView
                        }
                    ]
                },

                {
                    path:      'payments',
                    component: PaymentLayout,

                    children: [
                        {
                            path:      '',
                            name:      'PaymentRedirect',
                            component: PaymentRedirectView
                        }
                    ]
                },

                {
                    path:      'products',
                    component: Part,

                    children: [
                        {
                            path:      '',
                            name:      'PartList',
                            component: PartListView
                        },

                        {
                            path:      'new',
                            name:      'PartNew',
                            component: PartNewView
                        },

                        {
                            path:      ':id',
                            name:      'PartShow',
                            component: PartShowView
                        },

                        {
                            path:      'edit/:id',
                            name:      'PartEdit',
                            component: PartEditView
                        },
                    ]
                },

                {
                    path:      'reservations',
                    component: Part,

                    children: [
                        {
                            path:      '',
                            name:      'CartList',
                            component: CartListView
                        }
                    ]
                }
            ]
        },

        {
            path:      '/authentication',
            component: Authentication,

            children: [
                {
                    path:      'recovery/password',
                    name:      'RecoveryPassword',
                    component: RecoveryPasswordView,
                },

                {
                    path:      'sign-in',
                    name:      'SignIn',
                    component: SignInView,
                },

                {
                    path:      'sign-out',
                    name:      'SignOut',
                    component: SignOutView,
                },

                {
                    path:      'sign-up',
                    name:      'SignUp',
                    component: SignUpView,
                }
            ]
        }
    ],

    linkActiveClass:      'active',
    linkExactActiveClass: 'exact-active'
});

let application = createApp(ApplicationView);

application.config.globalProperties = {
    $API_HOST:    process.env.VUE_APP_API_HOST,
    $ASSETS_HOST: 'https://paexia.fra1.digitaloceanspaces.com'
};

application.use(router)
    .mount('#application');
