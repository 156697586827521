<template>
    <!--begin::Content wrapper-->
    <div class="d-flex flex-column flex-column-fluid">
        <!--begin::Toolbar-->
        <div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
            <!--begin::Toolbar container-->
            <div id="kt_app_toolbar_container" class="app-container container-xxl d-flex flex-stack">
                <!--begin::Page title-->
                <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <!--begin::Title-->
                    <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Moje zamówienia</h1>
                    <!--end::Title-->
                    <!--begin::Breadcrumb-->
                    <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                        <!--begin::Item-->
                        <li class="breadcrumb-item text-muted">
                            <a href="" class="text-muted text-hover-primary">Konto</a>
                        </li>
                        <!--end::Item-->
                        <!--begin::Item-->
                        <li class="breadcrumb-item">
                            <span class="bullet bg-gray-400 w-5px h-2px"></span>
                        </li>
                        <!--end::Item-->
                        <li class="breadcrumb-item text-muted">
                            <a href="" class="text-muted text-hover-primary">Moje zamówienia</a>
                        </li>
                        <li class="breadcrumb-item">
                            <span class="bullet bg-gray-400 w-5px h-2px"></span>
                        </li>
                        <!--begin::Item-->
                        <li class="breadcrumb-item text-muted">Szczegóły zamówienia</li>
                        <!--end::Item-->
                    </ul>
                    <!--end::Breadcrumb-->
                </div>
                <!--end::Page title-->
            </div>
            <!--end::Toolbar container-->
        </div>
        <!--end::Toolbar-->



        <!--begin::Content-->
        <div id="kt_app_content" class="app-content flex-column-fluid">
            <!--begin::Content container-->
            <div id="kt_app_content_container" class="app-container container-xxl">









<!--begin::Product List-->
													<div class="card card-flush py-4 flex-row-fluid overflow-hidden">
														<!--begin::Card header-->
														<div class="card-header">
															<div class="card-title">
																<h2>Zamówienie #{{ order.id }}</h2>
															</div>
														</div>
														<!--end::Card header-->
														<!--begin::Card body-->
														<div class="card-body pt-0">
															<div class="table-responsive">
																<!--begin::Table-->



<table class="align-middle table table-row-dashed fs-6 gy-5">
                            <thead>
                                <tr class="text-gray-600 fw-bold fs-7 text-uppercase gs-0">
                                    <th class="min-w-100px">Nazwa</th>
                                    <th class="min-w-100px text-end">Stan</th>
                                    <th class="min-w-100px text-end">Typ</th>
                                    <th class="min-w-100px text-end">Cena netto</th>
                                </tr>
                            </thead>
                            <tbody class="fw-semibold text-gray-600">
                                <tr v-for="product in order.products" v-bind:key="product.id">
                                    <td>
                                        <div class="d-flex align-items-center text-uppercase">
                                            <router-link class="symbol symbol-75px me-5" v-bind:to="{ name: 'PartShow', params: { id: product.id } }">
                                                <img v-bind:src="getImagePath(product)" class="symbol-label border border-2" />
                                            </router-link>

                                            <div class="d-flex justify-content-start flex-column">
                                                <router-link class="text-gray-600 text-hover-primary" v-bind:to="{ name: 'PartShow', params: { id: product.id } }">{{ product.name }}</router-link>

                                                <span class="text-gray-400 fw-semibold d-block fs-7">{{ product.vin }}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-end">
                                        <span class="badge badge-light-primary p-3">{{ dictionaries.states[product.state] ?? '-' }}</span>
                                    </td>
                                    <td class="text-end">
                                        <span class="badge badge-light-primary p-3">{{ dictionaries.types[product.type] ?? '-' }}</span>
                                    </td>
                                    <td class="text-end">{{ product.price }} zł</td>
                                </tr>

																		<!--begin::VAT-->
																		<tr>
																			<td colspan="3" class="text-end">VAT (23%)</td>
																			<td class="text-end">{{ order.amount_gross - order.amount }} zł</td>
																		</tr>
																		<!--end::VAT-->
																		<!--begin::Grand total-->
																		<tr>
																			<td colspan="3" class="fs-3 text-dark text-end">Łącznie</td>
																			<td class="text-dark fs-3 fw-bolder text-end">{{ order.amount_gross }} zł</td>
																		</tr>
																		<!--end::Grand total-->
                            </tbody>
                        </table>




																<!--end::Table-->
															</div>
														</div>
														<!--end::Card body-->


													</div>
													<!--end::Product List-->



											<div class="d-flex justify-content-end mt-10">
                                                <button class="btn btn-primary" type="submit">
                                                    <span class="indicator-label">Opłać</span>
                                                </button>
                                            </div>






            </div>
            <!--end::Content container-->
        </div>
        <!--end::Content-->
    </div>
    <!--end::Content wrapper-->
</template>

<script>
    export default {
        data() {
            return {
				dictionaries: {
                    states: {
                        new:          'Nowy',
                        used:         'Używany',
                        used_damaged: 'Używany (uszkodzony)'
                    },

                    types: {
                        original:    'Oryginał',
                        replacement: 'Zamiennik'
                    }
                },

                order: []
            }
        },

        async mounted() {
			document.body.setAttribute('data-kt-app-page-loading', 'on');

            try {
                const response = await fetch(this.$API_HOST + '/v1/orders/' + this.$route.params.id, {
                    method: 'GET',
                    mode:   'cors',

                    headers: {
                        'Authorization': 'Bearer ' + window.localStorage.getItem('token')
                    }
                });

                if (response.status === 401) {
                    this.$router.push({ name: 'SignIn' });
                }

                this.order = (await response.json()).data;

				setTimeout(() => {
					document.body.setAttribute('data-kt-app-page-loading', 'off');
				}, 250);
            } catch (error) {
            }
        },

		methods: {
            getImagePath(product) {
                if (product.attachments.length > 0) {
                    return this.$ASSETS_HOST + '/' + product.attachments[0].path;
                }

                return '/assets/media/icons/blank.svg';
            },
        }
    }
</script>
