<template>
    <!--begin::Content wrapper-->
    <div class="d-flex flex-column flex-column-fluid">
        <!--begin::Toolbar-->
        <div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
            <!--begin::Toolbar container-->
            <div id="kt_app_toolbar_container" class="app-container container-xxl d-flex flex-stack">
                <!--begin::Page title-->
                <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <!--begin::Title-->
                    <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Koszyk</h1>
                    <!--end::Title-->
                    <!--begin::Breadcrumb-->
                    <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                        <!--begin::Item-->
                        <li class="breadcrumb-item text-muted">
                            <a href="" class="text-muted text-hover-primary">Koszyk</a>
                        </li>
                        <!--end::Item-->
                        <!--begin::Item-->
                        <li class="breadcrumb-item">
                            <span class="bullet bg-gray-400 w-5px h-2px"></span>
                        </li>
                        <!--end::Item-->
                        <!--begin::Item-->
                        <li class="breadcrumb-item text-muted">Lista produktów</li>
                        <!--end::Item-->
                    </ul>
                    <!--end::Breadcrumb-->
                </div>
                <!--end::Page title-->
            </div>
            <!--end::Toolbar container-->
        </div>
        <!--end::Toolbar-->



        <!--begin::Content-->
        <div id="kt_app_content" class="app-content flex-column-fluid">
            <!--begin::Content container-->
            <div id="kt_app_content_container" class="app-container container-xxl">


                <div class="form d-flex flex-column flex-lg-row fv-plugins-bootstrap5 fv-plugins-framework">

















                <div class="d-flex flex-column flex-row-fluid gap-7 gap-lg-12">


































                <!--begin::Products-->
                <div class="card card-flush">
                    <!--begin::Card body-->
                    <div class="card-body pt-0">

                        <form class="form d-flex flex-column flex-lg-row requires-validation" v-on:submit.prevent="submit()" novalidate>
                            <table class="align-middle table table-row-dashed fs-6 gy-5">
                                <thead>
                                    <tr class="text-gray-600 fw-bold fs-7 text-uppercase gs-0">
                                        <th class="w-10px">
                                            <div class="form-check form-check-sm form-check-custom form-check-solid me-3">
                                                <input class="form-check-input selectAll d-none" type="checkbox" checked v-on:change="selectAll()">
                                            </div>
                                        </th>
                                        <th class="min-w-100px">Nazwa</th>
                                        <th class="min-w-100px">Stan</th>
                                        <th class="min-w-100px">Typ</th>
                                        <th class="min-w-100px">Cena</th>
                                        <th class="min-w-100px">Wygasa</th>
                                        <th class="min-w-100px">Opcje</th>
                                    </tr>
                                </thead>
                                <tbody class="fw-semibold text-gray-600" v-if="reservations.length">
                                    <tr v-for="reservation in reservations" v-bind:key="reservation.id">
                                        <td>
                                            <div class="form-check form-check-sm form-check-custom form-check-solid me-3">
                                                <input v-on:change="select()" class="form-check-input product" type="checkbox" v-model="ids" :value="reservation.product.id">
                                            </div>
                                        </td>
                                        <td>
                                            <div class="d-flex align-items-center text-uppercase">
                                                <router-link class="symbol symbol-75px me-5" v-bind:to="{ name: 'PartShow', params: { id: reservation.product.id } }">
                                                    <img v-bind:src="getImagePath(reservation.product)" class="symbol-label border border-2" />
                                                </router-link>

                                                <div class="d-flex justify-content-start flex-column">
                                                    <router-link class="text-gray-600 text-hover-primary" v-bind:to="{ name: 'PartShow', params: { id: reservation.product.id } }">{{ reservation.product.name }}</router-link>

                                                    <span class="text-gray-400 fw-semibold d-block fs-7">{{ reservation.product.vin }}</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <span class="badge badge-light p-3">{{ dictionaries.states[reservation.product.state] ?? '-' }}</span>
                                        </td>
                                        <td>
                                            <span class="badge badge-light p-3">{{ dictionaries.types[reservation.product.type] ?? '-' }}</span>
                                        </td>
                                        <td>{{ reservation.product.price }} zł</td>
                                        <td>{{ reservation.expire_at }}</td>
                                        <td></td>
                                    </tr>
                                </tbody>
                                <tbody class="fw-semibold text-gray-600" v-else>
                                    <tr>
                                        <td class="text-center" colspan="6">Brak produktów w koszyku</td>
                                    </tr>
                                </tbody>
                            </table>



</form>

                    </div>
                    <!--end::Card body-->

                </div>

                <!--end::Products-->

                                            <div class="d-flex justify-content-end">
                                                <button class="btn btn-primary" :disabled="!isOrderable" id="orderButton" type="submit" v-on:click="submit()">
                                                    <span class="indicator-label">Zamów</span>
                                                </button>
                                            </div>

                </div>
                </div>



            </div>
            <!--end::Content container-->
        </div>
        <!--end::Content-->
    </div>
    <!--end::Content wrapper-->
</template>

<script>
    export default {
        data() {
            return {
                dictionaries: {
                    states: {
                        new:          'Nowy',
                        used:         'Używany',
                        used_damaged: 'Używany (uszkodzony)'
                    },

                    types: {
                        original:    'Oryginał',
                        replacement: 'Zamiennik'
                    }
                },

                ids: [],

                reservations: [],

                pagination: {
                    pages: 1,
                    current: 1
                },

                isOrderable: false
            }
        },

        async mounted() {
            this.getProducts();
        },

        methods: {
            async getProducts() {
                document.body.setAttribute('data-kt-app-page-loading', 'on');

                try {
                    const response = await fetch(this.$API_HOST + '/v1/reservations', {
                        method: 'GET',
                        mode:   'cors',

                        headers: {
                            'Authorization': 'Bearer ' + window.localStorage.getItem('token')
                        }
                    });

                    if (response.status === 401) {
                        this.$router.push({ name: 'SignIn' });
                    }

                    this.reservations = (await response.json()).data;

                    this.reservations.forEach((reservation) => {
                        this.ids.push(reservation.product.id);
                    });

                    if (this.ids.length > 0) {
                        document.getElementById('orderButton').disabled = false;
                    }

                    console.log(this.ids);

                    setTimeout(() => {
                        document.body.setAttribute('data-kt-app-page-loading', 'off');
                    }, 250);
                } catch (error) {
                }
            },

            async submit() {
                document.body.setAttribute('data-kt-app-page-loading', 'on');

                const response = await fetch(this.$API_HOST + '/v1/orders', {
                    method: 'POST',
                    mode:   'cors',

                    headers: {
                        'Authorization': 'Bearer ' + window.localStorage.getItem('token'),
                        'Content-Type':  'application/json'
                    },

                    body: JSON.stringify(this.ids)
                });

                if (response.status === 401) {
                    this.$router.push({ name: 'SignIn' });
                }

                var order = (await response.json()).data;

                this.$router.push({ name: 'AccountOrderShow', params: { id: order.id } });
            },

            getImagePath(product) {
                if (product.attachments.length > 0) {
                    return this.$ASSETS_HOST + '/' + product.attachments[0].path;
                }

                return '/assets/media/icons/blank.svg';
            },

            selectAll() {
                var count = 0;
                const checked = document.querySelector('input.selectAll').checked;

                document.querySelectorAll('input.product').forEach(function (element) {
                    if (checked) {
                        element.checked = true;
                        count++;
                    } else {
                        element.checked = false;
                    }
                });

                if (count > 0) {
                    document.getElementById('orderButton').disabled = false;
                } else {
                    document.getElementById('orderButton').disabled = true;
                }
            },

            select() {
                if (this.ids.length > 0) {
                    document.getElementById('orderButton').disabled = false;
                } else {
                    document.getElementById('orderButton').disabled = true;
                    document.querySelector('input.selectAll').checked = false;
                }
            }
        }
    }
</script>
