<template>
    <div class="d-flex flex-column flex-lg-row flex-column-fluid app-blank">
        <div class="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
            <div class="d-flex flex-center flex-column flex-lg-row-fluid">
                <div class="w-lg-500px p-10">
                    <form class="form w-100" novalidate="novalidate" v-on:submit="submit">
                        <div class="text-center mb-15">
                            <h1 class="text-gray-700 fw-bolder mb-3">Odzyskiwanie hasła</h1>

                            <div class="text-gray-500 fw-semibold fs-6">PartsExpert</div>
                        </div>

                        <div class="fv-row mb-4">
                            <input type="text" placeholder="Adres e-mail" name="email" autocomplete="off" class="form-control form-control-solid border-0" />
                        </div>

                        <div class="d-grid mb-5">
                            <button type="submit" class="btn btn-primary">
                                <span class="indicator-label">Odzyskaj</span>
                            </button>
                        </div>

                        <div class="text-gray-500 text-center fw-semibold">
                            Pamiętasz hasło? <router-link class="link-primary" v-bind:to="{ name: 'SignIn' }">Zaloguj się.</router-link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        methods: {
            async submit(event) {
                if (event) {
                    event.preventDefault();
                }

                this.$router.push({ name: 'Home' });
            }
        }
    }
</script>
