<template>
    <!--begin::Content wrapper-->
    <div class="d-flex flex-column flex-column-fluid">
        <!--begin::Toolbar-->
        <div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
            <!--begin::Toolbar container-->
            <div id="kt_app_toolbar_container" class="app-container container-xxl d-flex flex-stack">
                <!--begin::Page title-->
                <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <!--begin::Title-->
                    <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Moje produkty</h1>
                    <!--end::Title-->
                    <!--begin::Breadcrumb-->
                    <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                        <!--begin::Item-->
                        <li class="breadcrumb-item text-muted">
                            <a href="" class="text-muted text-hover-primary">Konto</a>
                        </li>
                        <!--end::Item-->
                        <!--begin::Item-->
                        <li class="breadcrumb-item">
                            <span class="bullet bg-gray-400 w-5px h-2px"></span>
                        </li>
                        <!--end::Item-->
                        <li class="breadcrumb-item text-muted">
                            <a href="" class="text-muted text-hover-primary">Moje produkty</a>
                        </li>
                        <li class="breadcrumb-item">
                            <span class="bullet bg-gray-400 w-5px h-2px"></span>
                        </li>
                        <!--begin::Item-->
                        <li class="breadcrumb-item text-muted">Lista produktów</li>
                        <!--end::Item-->
                    </ul>
                    <!--end::Breadcrumb-->
                </div>
                <!--end::Page title-->
                <!--begin::Actions-->
                <div class="d-flex align-items-center gap-2 gap-lg-3">
                    <!--begin::Secondary button-->
                    <a class="btn btn-sm fw-bold bg-body btn-color-gray-700 btn-active-color-primary" href="https://www.dat.de/AuthorizationManager/authMgr/login.htm" target="_blank">Dodaj z DAT</a>
                    <!--end::Secondary button-->
                    <!--begin::Primary button-->
                    <router-link class="btn btn-sm fw-bold btn-primary" v-bind:to="{ name: 'PartNew' }">Dodaj</router-link>
                    <!--end::Primary button-->
                </div>
                <!--end::Actions-->
            </div>
            <!--end::Toolbar container-->
        </div>
        <!--end::Toolbar-->



        <!--begin::Content-->
        <div id="kt_app_content" class="app-content flex-column-fluid">
            <!--begin::Content container-->
            <div id="kt_app_content_container" class="app-container container-xxl">


                <div class="form d-flex flex-column flex-lg-row fv-plugins-bootstrap5 fv-plugins-framework">



                    <!-- BEGIN: LEFT -->
                    <div class="flex-column flex-lg-row-auto w-100 w-lg-250px w-xxl-325px mb-8 mb-lg-0 me-lg-9 me-5">
                        <form v-on:submit.prevent="getProducts()">
                            <div class="card">
                                <div class="card-body">
                                    <div class="mb-5">
                                        <label class="form-label">Status</label>

                                        <select class="form-select form-select-solid" v-model="filters.status">
                                            <option value="">Wszystkie</option>
                                            <option value="draft">Szkic</option>
                                            <option value="published">Opublikowany</option>
                                            <option value="reserved">Zarezerwowany</option>
                                            <option value="ordered">Zamówiony</option>
                                        </select>
                                    </div>

                                    <div class="separator separator-dashed my-8"></div>

                                    <div class="mb-5">
                                        <label class="form-label">Typ</label>

                                        <select class="form-select form-select-solid" v-model="filters.type">
                                            <option value="">Wszystkie</option>
                                            <option value="original">Oryginały</option>
                                            <option value="replacement">Zamienniki</option>
                                        </select>
                                    </div>

                                    <div class="mb-5">
                                        <label class="form-label">Stan</label>

                                        <select class="form-select form-select-solid" v-model="filters.state">
                                            <option value="">Wszystkie</option>
                                            <option value="new">Nowy</option>
                                            <option value="used">Używany</option>
                                            <option value="used_damaged">Używany (uszkodzony)</option>
                                        </select>
                                    </div>

                                    <div class="separator separator-dashed my-8"></div>

                                    <div class="mb-5">
                                        <label class="form-label">Kod OE</label>

                                        <input class="form-control form-control-solid" v-model="filters.codes.oe" />
                                    </div>

                                    <div class="mb-5">
                                        <label class="form-label">Kod OEM</label>

                                        <input class="form-control form-control-solid" v-model="filters.codes.oem" />
                                    </div>

                                    <div class="separator separator-dashed my-8"></div>

                                    <div class="mb-5">
                                        <label class="form-label">VIN</label>

                                        <input class="form-control form-control-solid" v-model="filters.vin" />
                                    </div>

                                    <div class="separator separator-dashed my-8"></div>

                                    <div class="mb-5">
                                        <label class="form-label">Cena od</label>

                                        <input class="form-control form-control-solid" v-model="filters.price.from" />
                                    </div>

                                    <div class="mb-5">
                                        <label class="form-label">Cena do</label>

                                        <input class="form-control form-control-solid" v-model="filters.price.to" />
                                    </div>

                                    <div class="mt-10 d-flex justify-content-end">
                                        <button class="btn btn-light me-3" type="reset" v-on:click.prevent="reset()">Wyczyść</button>
                                        <button class="btn btn-primary" v-on:click.prevent="getProducts()">Filtruj</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!-- END: LEFT -->


                    <div class="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
                        <!--begin::Products-->
                        <div class="card card-flush">
                            <!--begin::Card body-->
                            <div class="card-body pt-0">

                                <table class="align-middle table table-row-dashed fs-6 gy-5">
                                    <thead>
                                        <tr class="text-gray-600 fw-bold fs-7 text-uppercase gs-0">
                                            <th class="min-w-100px">Nazwa</th>
                                            <th class="min-w-100px">Status</th>
                                            <th class="min-w-100px">Stan</th>
                                            <!-- <th class="min-w-100px">Typ</th> -->
                                            <th class="min-w-100px">Data dodania</th>
                                            <th class="min-w-100px">Cena</th>
                                            <th class="min-w-100px text-end">Akcje</th>
                                        </tr>
                                    </thead>
                                    <tbody class="fw-semibold text-gray-600" v-if="products.length">
                                        <tr v-for="product in products" v-bind:key="product.id">
                                            <td>
                                                <div class="d-flex align-items-center text-uppercase">
                                                    <router-link class="symbol symbol-75px me-5" v-bind:to="{ name: 'PartShow', params: { id: product.id } }">
                                                        <img v-bind:src="getImagePath(product)" class="symbol-label border border-2" />
                                                    </router-link>

                                                    <div class="d-flex justify-content-start flex-column">
                                                        <router-link class="text-gray-600 text-hover-primary" v-bind:to="{ name: 'PartShow', params: { id: product.id } }">{{ product.name }}</router-link>

                                                        <span class="text-gray-400 fw-semibold d-block fs-7">{{ product.vin }}</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <span class="badge badge-light-primary p-3">{{ dictionaries.statuses[product.status] ?? '-' }}</span>
                                            </td>
                                            <td>
                                                <span class="badge badge-light-primary p-3">{{ dictionaries.states[product.state] ?? '-' }}</span>
                                            </td>
                                            <!-- <td>
                                                <span class="badge badge-light-primary p-3">{{ dictionaries.types[product.type] ?? '-' }}</span>
                                            </td> -->
                                            <td>{{ new Date(product.created_at).toLocaleDateString() }}</td>
                                            <td>{{ product.final_price }} zł</td>
                                            <td class="text-end">
                                                <router-link class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px mx-1" v-bind:to="{ name: 'PartEdit', params: { id: product.id } }" v-if="canEdit(product)">
                                                    <i class="bi bi-pencil-fill fs-1x"></i>
                                                </router-link>
                                                <a href="#" v-on:click.prevent="deleteProduct(product.id)" class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px mx-1" v-if="canDelete(product)">
                                                    <i class="bi bi-trash-fill fs-1x"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody class="fw-semibold text-gray-600" v-else>
                                        <tr>
                                            <td class="text-center" colspan="5">Brak produktów</td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div class="row">
                                    <div class="col-12 d-flex justify-content-end">
                                        <ul class="pagination">
                                            <li v-for="index in pagination.pages" :key="index" class="paginate_button page-item" :class="{ active: index == this.$route.query.page }">
                                                <router-link active-class="" class="page-link" v-bind:to="{ name: 'AccountPartList', query: { page: index } }">{{ index }}</router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                            <!--end::Card body-->
                        </div>
                        <!--end::Products-->
                    </div>



                </div>


            </div>
            <!--end::Content container-->
        </div>
        <!--end::Content-->
    </div>
    <!--end::Content wrapper-->
</template>

<script>
    export default {
        data() {
            return {
                dictionaries: {
                    statuses: {
                        draft:     'Szkic',
                        published: 'Opublikowany',
                        reserved:  'Zarezerwowany',
                        ordered:   'Zamówiony',
                    },

                    states: {
                        new:          'Nowy',
                        used:         'Używany',
                        used_damaged: 'Używany (uszkodzony)'
                    },

                    types: {
                        original:    'Oryginał',
                        replacement: 'Zamiennik'
                    }
                },

                products: [],

                pagination: {
                    pages: 1,
                    current: 1
                },

                filters: {
                    vin:    '',
                    type:   '',
                    state:  '',
                    status: '',

                    codes: {
                        oe:  '',
                        oem: ''
                    },

                    price: {
                        from: '',
                        to:   ''
                    }
                }
            }
        },

        created() {
            document.body.setAttribute('data-kt-app-page-loading', 'on');
        },

        async mounted() {
            this.getProducts();
        },

        methods: {
            async getProducts() {
                document.body.setAttribute('data-kt-app-page-loading', 'on');

                try {
                    let queryParameters = '';

                    queryParameters += '?filters[vin]=' + this.filters.vin;
                    queryParameters += '&filters[type]=' + this.filters.type;
                    queryParameters += '&filters[state]=' + this.filters.state;
                    queryParameters += '&filters[status]=' + this.filters.status;

                    for (const [type, value] of Object.entries(this.filters.codes)) {
                        queryParameters += '&filters[codes][' + type + ']=' + value;
                    }

                    queryParameters += '&filters[price][from]=' + this.filters.price.from;
                    queryParameters += '&filters[price][to]=' + this.filters.price.to;

                    queryParameters += '&page=' + this.$route.query.page;

                    const response = await fetch(this.$API_HOST + '/v1/account/products' + queryParameters, {
                        method: 'GET',
                        mode:   'cors',

                        headers: {
                            'Authorization': 'Bearer ' + window.localStorage.getItem('token')
                        }
                    });

                    if (response.status === 401) {
                        this.$router.push({ name: 'SignIn' });
                    }

                    const data = (await response.json());

                    this.products   = data.data;
                    this.pagination = data.pagination;

                    setTimeout(() => {
                        document.body.setAttribute('data-kt-app-page-loading', 'off');
                    }, 250);
                } catch (error) {
                    console.error(error);
                }
            },

            async deleteProduct(id) {
                if (!confirm("Czy na pewno usunąć product?")) {
                    return false;
                }

                document.body.setAttribute('data-kt-app-page-loading', 'on');

                const response = await fetch(this.$API_HOST + '/v1/account/products/' + id, {
                    method: 'DELETE',
                    mode:   'cors',

                    headers: {
                        'Authorization': 'Bearer ' + window.localStorage.getItem('token')
                    }
                });

                if (response.status === 401) {
                    this.$router.push({ name: 'SignIn' });
                }

                this.getProducts();
            },

            getImagePath(product) {
                if (product.attachments.length > 0) {
                    return this.$ASSETS_HOST + '/' + product.attachments[0].path;
                }

                return '/assets/media/icons/blank.svg';
            },

            canEdit(product) {
                return product.status === 'draft' || product.status === 'published';
            },

            canDelete(product) {
                return product.status === 'draft' || product.status === 'published';
            },

            reset() {
                this.filters.vin    = '';
                this.filters.type   = '';
                this.filters.state  = '';
                this.filters.status = '';

                this.filters.codes.oe  = '';
                this.filters.codes.oem = '';

                this.filters.price.from = '';
                this.filters.price.to   = '';

                this.getProducts();
            }
        },

        watch: {
            '$route' (to, from) {
                this.getProducts();

                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        }
    }
</script>
