<template>
    <!--begin::Content wrapper-->
    <div class="d-flex flex-column flex-column-fluid">
        <!--begin::Toolbar-->
        <div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
            <!--begin::Toolbar container-->
            <div id="kt_app_toolbar_container" class="app-container container-xxl d-flex flex-stack">
                <!--begin::Page title-->
                <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <!--begin::Title-->
                    <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Użytkownicy</h1>
                    <!--end::Title-->
                    <!--begin::Breadcrumb-->
                    <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                        <!--begin::Item-->
                        <li class="breadcrumb-item text-muted">
                            <a href="" class="text-muted text-hover-primary">Ustawienia administratora</a>
                        </li>
                        <!--end::Item-->
                        <!--begin::Item-->
                        <li class="breadcrumb-item">
                            <span class="bullet bg-gray-400 w-5px h-2px"></span>
                        </li>
                        <!--end::Item-->
                        <li class="breadcrumb-item text-muted">
                            <a href="" class="text-muted text-hover-primary">Użytkownicy</a>
                        </li>
                        <li class="breadcrumb-item">
                            <span class="bullet bg-gray-400 w-5px h-2px"></span>
                        </li>
                        <!--begin::Item-->
                        <li class="breadcrumb-item text-muted">Lista użytkowników</li>
                        <!--end::Item-->
                    </ul>
                    <!--end::Breadcrumb-->
                </div>
                <!--end::Page title-->
                <!--begin::Actions-->
                <div class="d-flex align-items-center gap-2 gap-lg-3">
                    <!--begin::Primary button-->
                    <button class="btn btn-sm fw-bold btn-success" v-on:click="downloadCsv()">Export do CSV</button>
                    <!--end::Primary button-->
                </div>
                <!--end::Actions-->
            </div>
            <!--end::Toolbar container-->
        </div>
        <!--end::Toolbar-->



        <!--begin::Content-->
        <div id="kt_app_content" class="app-content flex-column-fluid">
            <!--begin::Content container-->
            <div id="kt_app_content_container" class="app-container container-xxl">


                <div class="form d-flex flex-column flex-lg-row fv-plugins-bootstrap5 fv-plugins-framework">



                    <!-- BEGIN: LEFT -->
                    <div class="flex-column flex-lg-row-auto w-100 w-lg-250px w-xxl-325px mb-8 mb-lg-0 me-lg-9 me-5">
                        <form v-on:submit.prevent="getUsers()">
                            <div class="card">
                                <div class="card-body">
                                    <div class="mb-5">
                                        <label class="form-label">Nazwa</label>

                                        <input class="form-control form-control-solid" v-model="filters.name" />
                                    </div>

                                    <div class="separator separator-dashed my-8"></div>

                                    <div class="mb-5">
                                        <label class="form-label">Numer telefonu</label>

                                        <input class="form-control form-control-solid" v-model="filters.phone_number" />
                                    </div>

                                    <div class="mb-5">
                                        <label class="form-label">Adres e-mail</label>

                                        <input class="form-control form-control-solid" v-model="filters.email" />
                                    </div>

                                    <div class="mb-5">
                                        <label class="form-label">Dodatkowy adres e-mail</label>

                                        <input class="form-control form-control-solid" v-model="filters.email2" />
                                    </div>

                                    <div class="separator separator-dashed my-8"></div>

                                    <div class="mb-5">
                                        <label class="form-label">Adres</label>

                                        <input class="form-control form-control-solid" v-model="filters.address" />
                                    </div>

                                    <div class="mb-5">
                                        <label class="form-label">Miasto</label>

                                        <input class="form-control form-control-solid" v-model="filters.city" />
                                    </div>

                                    <div class="mb-5">
                                        <label class="form-label">Kod pocztowy</label>

                                        <input class="form-control form-control-solid" v-model="filters.postal_code" />
                                    </div>

                                    <div class="separator separator-dashed my-8"></div>

                                    <div class="mb-5">
                                        <label class="form-label">NIP</label>

                                        <input class="form-control form-control-solid" v-model="filters.tax_number" />
                                    </div>

                                    <div class="mt-10 d-flex justify-content-end">
                                        <button class="btn btn-light me-3" type="reset" v-on:click.prevent="reset()">Wyczyść</button>
                                        <button class="btn btn-primary" v-on:click.prevent="getUsers()">Filtruj</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!-- END: LEFT -->


                    <div class="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
                        <!--begin::Products-->
                        <div class="card card-flush">
                            <!--begin::Card body-->
                            <div class="card-body pt-0">

                                <table class="align-middle table table-row-dashed fs-6 gy-5">
                                    <thead>
                                        <tr class="text-gray-600 fw-bold fs-7 text-uppercase gs-0">
                                            <th class="min-w-100px">Nazwa</th>
                                            <th class="min-w-100px">Adres e-mail</th>
                                            <th class="min-w-100px">NIP</th>
                                            <th class="min-w-100px">Aktywny</th>
                                            <th class="min-w-100px">Data rejestracji</th>
                                            <th class="min-w-100px">Akcje</th>
                                        </tr>
                                    </thead>
                                    <tbody class="fw-semibold text-gray-600" v-if="users.length">
                                        <tr v-for="user in users" v-bind:key="user.id">
                                            <td>
                                                {{ user.name }}

                                                <span class="text-gray-400 fw-semibold d-block fs-7">{{ user.id }}</span>
                                            </td>
                                            <td>
                                                {{ user.email }}

                                                <span class="text-gray-400 fw-semibold d-block fs-7">{{ user.email2 }}</span>
                                            </td>
                                            <td>{{ user.tax_number }}</td>
                                            <td>
                                                <span v-if="user.active == 1" class="badge badge-light-success p-3">Tak</span>
                                                <span v-if="user.active == 0" class="badge badge-light-danger p-3">Nie</span>

                                            </td>
                                            <td>{{ new Date(user.created_at).toLocaleDateString() }}</td>
                                            <td>
                                                <router-link class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px mx-1" v-bind:to="{ name: 'AdministrationUserEdit', params: { id: user.id } }">
                                                    <i class="bi bi-pencil-fill fs-1x"></i>
                                                </router-link>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody class="fw-semibold text-gray-600" v-else>
                                        <tr>
                                            <td class="text-center" colspan="5">Brak użytkowników</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                            <!--end::Card body-->
                        </div>
                        <!--end::Products-->
                    </div>



                </div>


            </div>
            <!--end::Content container-->
        </div>
        <!--end::Content-->
    </div>
    <!--end::Content wrapper-->
</template>

<script>
    export default {
        data() {
            return {
                users: [],

                pagination: {
                    pages: 1,
                    current: 1
                },

                filters: {
                    name:         '',
                    email:        '',
                    email2:       '',
                    phone_number: '',
                    tax_number:   '',
                    address:      '',
                    city:         '',
                    postal_code:  ''
                }
            }
        },

        created() {
            document.body.setAttribute('data-kt-app-page-loading', 'on');
        },

        async mounted() {
            this.getUsers();
        },

        methods: {
            async getUsers() {
                document.body.setAttribute('data-kt-app-page-loading', 'on');

                try {
                    let queryParameters = '';

                    queryParameters += '?filters[name]='         + this.filters.name;
                    queryParameters += '&filters[email]='        + this.filters.email;
                    queryParameters += '&filters[email2]='       + this.filters.email2;
                    queryParameters += '&filters[phone_number]=' + this.filters.phone_number;
                    queryParameters += '&filters[tax_number]='   + this.filters.tax_number;
                    queryParameters += '&filters[address]='      + this.filters.address;
                    queryParameters += '&filters[city]='         + this.filters.city;
                    queryParameters += '&filters[postal_code]='  + this.filters.postal_code;

                    queryParameters += '&page=' + this.$route.query.page;

                    const response = await fetch(this.$API_HOST + '/v1/administration/users' + queryParameters, {
                        method: 'GET',
                        mode:   'cors',

                        headers: {
                            'Authorization': 'Bearer ' + window.localStorage.getItem('token')
                        }
                    });

                    if (response.status === 401) {
                        this.$router.push({ name: 'SignIn' });
                    }

                    const data = (await response.json());

                    this.users      = data.data;
                    this.pagination = data.pagination;

                    setTimeout(() => {
                        document.body.setAttribute('data-kt-app-page-loading', 'off');
                    }, 250);
                } catch (error) {
                    console.error(error);
                }
            },

            downloadCsv() {
                let queryParameters = '';

                queryParameters += '?filters[name]='         + this.filters.name;
                queryParameters += '&filters[email]='        + this.filters.email;
                queryParameters += '&filters[email2]='       + this.filters.email2;
                queryParameters += '&filters[phone_number]=' + this.filters.phone_number;
                queryParameters += '&filters[tax_number]='   + this.filters.tax_number;
                queryParameters += '&filters[address]='      + this.filters.address;
                queryParameters += '&filters[city]='         + this.filters.city;
                queryParameters += '&filters[postal_code]='  + this.filters.postal_code;

                fetch(this.$API_HOST + '/v1/exports/users' + queryParameters, {
                    method: 'get',
                    mode: 'cors',

                    headers: {
                        'Authorization': 'Bearer ' + window.localStorage.getItem('token')
                    }
                })
                .then(res => res.blob())
                .then(res => {
                    const aElement = document.createElement('a');
                    aElement.setAttribute('download', 'users.csv');
                    const href = URL.createObjectURL(res);
                    aElement.href = href;
                    aElement.setAttribute('target', '_blank');
                    aElement.click();
                    URL.revokeObjectURL(href);
                });
            },

            reset() {
                this.filters.name         = '';
                this.filters.email        = '';
                this.filters.email2       = '';
                this.filters.phone_number = '';
                this.filters.tax_number   = '';
                this.filters.address      = '';
                this.filters.city         = '';
                this.filters.postal_code  = '';

                this.getUsers();
            }
        },

        watch: {
            '$route' (to, from) {
                this.getUsers();

                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        }
    }
</script>
