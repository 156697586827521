<template>
    <div class="app-page flex-column flex-column-fluid">
      <!--begin::Header-->
      <div class="app-header">
        <!--begin::Header container-->
        <div class="app-container container-xxl d-flex align-items-stretch justify-content-between" id="kt_app_header_container">
          <!--begin::Logo-->

          <div class="d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-5">
            <a href="/">
              <img alt="PartsExpert Logo" src="/assets/logo.jpg" class="h-40px h-lg-40px app-sidebar-logo-default theme-light-show" />
            </a>
          </div>
          <!--end::Logo-->
          <!--begin::Header wrapper-->
          <div class="d-flex align-items-stretch justify-content-between flex-lg-grow-1" id="kt_app_header_wrapper">


            <!--begin::Menu wrapper-->
            <div class="app-header-menu app-header-mobile-drawer align-items-stretch" data-kt-drawer="true" data-kt-drawer-name="app-header-menu" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="250px" data-kt-drawer-direction="end" data-kt-drawer-toggle="#kt_app_header_menu_toggle" data-kt-swapper="true" data-kt-swapper-mode="{default: 'append', lg: 'prepend'}" data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}">
              <!--begin::Menu-->
              <div class="menu menu-rounded menu-column menu-lg-row my-5 my-lg-0 align-items-stretch fw-semibold px-2 px-lg-0" id="kt_app_header_menu" data-kt-menu="true">
                <!--begin:Menu item-->
                <div class="menu-item menu-here-bg menu-lg-down-accordion me-0 me-lg-2">
                  <!--begin:Menu link-->
                    <router-link class="menu-link menu-title" v-bind:to="{ name: 'PartList' }">Produkty</router-link>
                  <!--end:Menu link-->

                </div>
                <!--end:Menu item-->
                <!--begin:Menu item-->
                <div class="menu-item menu-here-bg menu-lg-down-accordion me-0 me-lg-2">
                  <!--begin:Menu link-->
                    <router-link class="menu-link menu-title" v-bind:to="{ name: 'CartList' }">Koszyk</router-link>
                  <!--end:Menu link-->

                </div>
                <!--end:Menu item-->
              </div>
              <!--end::Menu-->
            </div>
            <!--end::Menu wrapper-->


            <!--begin::Navbar-->
            <div class="app-navbar flex-shrink-0">

              <!--begin::User menu-->
              <div class="app-navbar-item ms-1 ms-lg-3" id="kt_header_user_menu_toggle">
                <!--begin::Menu wrapper-->
                <div class="cursor-pointer symbol symbol-35px symbol-md-40px" data-kt-menu-trigger="click" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
                  <img src="/assets/media/avatars/blank.png" alt="user" />
                </div>
                <!--begin::User account menu-->
                <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px" data-kt-menu="true">
                  <!--begin::Menu item-->
                  <div class="menu-item px-3">
                    <div class="menu-content d-flex align-items-center px-3">
                      <!--begin::Avatar-->
                      <div class="symbol symbol-50px me-5">
                        <img alt="Logo" src="/assets/media/avatars/blank.png" />
                      </div>
                      <!--end::Avatar-->
                      <!--begin::Username-->
                      <div class="d-flex flex-column">
                        <div class="fw-bold d-flex align-items-center fs-5" id="userName">-</div>
                        <a href="#" class="fw-semibold text-muted text-hover-primary fs-7" id="userEmail">-</a>
                      </div>
                      <!--end::Username-->
                    </div>
                  </div>
                  <!--end::Menu item-->
                  <!--begin::Menu separator-->
                  <div class="separator my-2"></div>
                  <!--end::Menu separator-->
                  <!--begin::Menu item-->
                  <div class="menu-item px-5">
                    <router-link class="menu-link px-5" v-bind:to="{ name: 'AccountPartList' }">Moje produkty</router-link>
                  </div>
                  <!--end::Menu item-->

                  <!--begin::Menu item-->
                  <div class="menu-item px-5">
                    <router-link class="menu-link px-5" v-bind:to="{ name: 'AccountOrderList' }">Moje zamówienia</router-link>
                  </div>
                  <!--end::Menu item-->






                  <!--begin::Menu separator-->
                  <div class="separator my-2"></div>
                  <!--end::Menu separator-->

                  <!--begin::Menu item-->
                    <div class="menu-item px-5">
                      <router-link class="menu-link px-5" v-bind:to="{ name: 'AccountProfileEdit' }">Ustawienia konta</router-link>
                    </div>
                  <!--end::Menu item-->

                       <!--begin::Menu item-->
										<div class="menu-item px-5" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="left-start">
											<a href="#" class="menu-link px-5">
												<span class="menu-title">Ustawienia administratora</span>
												<span class="menu-arrow"></span>
											</a>
											<!--begin::Menu sub-->
											<div class="menu-sub menu-sub-dropdown w-175px py-4">
												<!--begin::Menu item-->
												<div class="menu-item px-3">
													<router-link class="menu-link px-5" v-bind:to="{ name: 'AdministrationProductList' }">Produkty</router-link>
												</div>
												<!--end::Menu item-->
												<!--begin::Menu item-->
												<div class="menu-item px-3">
													<router-link class="menu-link px-5" v-bind:to="{ name: 'AdministrationOrderList' }">Zamówienia</router-link>
												</div>
												<!--end::Menu item-->
                        <!--begin::Menu item-->
												<div class="menu-item px-3">
                          <router-link class="menu-link px-5" v-bind:to="{ name: 'AdministrationUserList' }">Użytkownicy</router-link>
												</div>
												<!--end::Menu item-->


											</div>
											<!--end::Menu sub-->
										</div>
										<!--end::Menu item-->

                  <!--begin::Menu item-->
                  <div class="menu-item px-5">
                    <router-link class="menu-link px-5" v-bind:to="{ name: 'SignOut' }">Wyloguj</router-link>
                  </div>
                  <!--end::Menu item-->
                </div>
                <!--end::User account menu-->
                <!--end::Menu wrapper-->
              </div>
              <!--end::User menu-->

            </div>
            <!--end::Navbar-->
          </div>
          <!--end::Header wrapper-->
        </div>
        <!--end::Header container-->
      </div>
      <!--end::Header-->




        <!--begin::Wrapper-->
        <div class="app-wrapper flex-column flex-row-fluid">
            <!--begin::Main-->
            <div class="app-main flex-column flex-row-fluid" id="kt_app_main">
                <router-view />

                <!--begin::Footer-->
                <div class="app-footer">
                    <!--begin::Footer container-->
                    <div class="app-container container-xxl d-flex flex-column flex-md-row flex-center flex-md-stack py-3">
                        <!--begin::Copyright-->
                        <div class="text-dark order-2 order-md-1">
                            <span class="text-muted fw-semibold me-1">2023 &copy;</span>
                            <a href="/" class="text-gray-800 text-hover-primary">Parts Expert</a>
                        </div>
                        <!--end::Copyright-->
                    </div>
                    <!--end::Footer container-->
                </div>
                <!--end::Footer-->
            </div>
            <!--end:::Main-->
        </div>
        <!--end::Wrapper-->




    </div>
</template>

<script>
    export default {
        async mounted() {
            const user = JSON.parse(
                window.localStorage.getItem('user')
            );

            document.getElementById('userName').innerText = user.name;
            document.getElementById('userEmail').innerText = user.email;
        }
    }
</script>
