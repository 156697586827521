<template>
</template>

<script>
    export default {
        mounted() {
            window.localStorage.clear();

            this.$router.push({ name: 'SignIn' });
        }
    }
</script>
