<template>
    <div class="d-flex flex-column flex-lg-row flex-column-fluid app-blank">
        <div class="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
            <div class="d-flex flex-center flex-column flex-lg-row-fluid">
                <div class="w-lg-500px p-10">
                    <form class="form w-100" v-on:submit.prevent="submit" novalidate>
                        <div class="text-center mb-15">
                            <h1 class="text-gray-700 fw-bolder mb-3">Rejestracja</h1>

                            <div class="text-gray-500 fw-semibold fs-6">PartsExpert</div>
                        </div>

                        <div class="fv-row mb-4">
                            <input class="form-control form-control-solid border-0" type="email" placeholder="Adres e-mail" autocomplete="off" v-model="email" required />
                        </div>

                        <div class="fv-row mb-4">
                            <input class="form-control form-control-solid border-0" type="password" placeholder="Hasło" autocomplete="off" v-model="password" required />
                        </div>

                        <div class="fv-row mb-4">
                            <input class="form-control form-control-solid border-0" type="password" placeholder="Powtórz hasło" autocomplete="off" v-model="password2" required />
                        </div>

                        <div class="separator separator-dashed my-8"></div>

                        <div class="fv-row mb-4">
                            <input class="form-control form-control-solid border-0" type="text" placeholder="Nazwa firmy" autocomplete="off" v-model="name" required />
                        </div>

                        <div class="fv-row mb-4">
                            <input class="form-control form-control-solid border-0" type="text" placeholder="Imię" autocomplete="off" v-model="first_name" />
                        </div>

                        <div class="fv-row mb-4">
                            <input class="form-control form-control-solid border-0" type="text" placeholder="Nazwisko" autocomplete="off" v-model="last_name" />
                        </div>

                        <div class="fv-row mb-4">
                            <input class="form-control form-control-solid border-0" type="text" placeholder="NIP" autocomplete="off" v-model="taxNumber" required />
                        </div>

                        <div class="separator separator-dashed my-8"></div>

                        <div class="fv-row mb-4">
                            <input class="form-control form-control-solid border-0" type="text" placeholder="Address" autocomplete="off" v-model="address" required />
                        </div>

                        <div class="fv-row mb-4">
                            <input class="form-control form-control-solid border-0" type="text" placeholder="Miasto" autocomplete="off" v-model="city" required />
                        </div>

                        <div class="fv-row mb-4">
                            <input class="form-control form-control-solid border-0" type="text" placeholder="Kod pocztowy" autocomplete="off" v-model="postalCode" required />
                        </div>

                        <div class="separator separator-dashed my-8"></div>

                        <div class="fv-row mb-4">
                            <input class="form-control form-control-solid border-0" type="text" placeholder="Numer telefonu" autocomplete="off" v-model="phoneNumber" />
                        </div>

                        <div class="fv-row mb-4">
                            <input class="form-control form-control-solid border-0" type="text" placeholder="Dodatkowy adres e-mail" autocomplete="off" v-model="email2" />
                        </div>

                        <div class="d-grid mb-5">
                            <button type="submit" class="btn btn-primary">
                                <span class="indicator-label">Zarejestruj</span>
                            </button>
                        </div>

                        <div class="text-gray-500 text-center fw-semibold">
                            Masz konto? <router-link class="link-primary" v-bind:to="{ name: 'SignIn' }">Zaloguj się.</router-link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                email:       '',
                email2:      '',
                password:    '',
                password2:   '',
                name:        '',
                first_name:  '',
                last_name:   '',
                taxNumber:   '',
                address:     '',
                city:        '',
                postalCode:  '',
                phoneNumber: ''
            }
        },

        methods: {
            async submit(event) {
                if (!event.target.checkValidity()) {
                    event.target.classList.add('was-validated');

                    return;
                }

                try {
                    const response = await fetch(this.$API_HOST + '/authentication/sign-up', {
                        method: 'POST',
                        mode:   'cors',

                        headers: {
                            'Content-Type': 'application/json'
                        },

                        body: JSON.stringify({
                            'email':        this.email,
                            'email2':       this.email2,
                            'password':     this.password,
                            'name':         this.name,
                            'first_name':   this.first_name,
                            'last_name':    this.last_name,
                            'address':      this.address,
                            'city':         this.city,
                            'postal_code':  this.postalCode,
                            'tax_number':   this.taxNumber,
                            'phone_number': this.phoneNumber,
                        })
                    });

                    this.$router.push({ name: 'SignIn' });
                } catch (error) {
                }
            }
        }
    }
</script>
