<template>
    <!--begin::Content wrapper-->
    <div class="d-flex flex-column flex-column-fluid">
        <!--begin::Toolbar-->
        <div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
            <!--begin::Toolbar container-->
            <div id="kt_app_toolbar_container" class="app-container container-xxl d-flex flex-stack">
                <!--begin::Page title-->
                <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <!--begin::Title-->
                    <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Moje zamówienia</h1>
                    <!--end::Title-->
                    <!--begin::Breadcrumb-->
                    <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                        <!--begin::Item-->
                        <li class="breadcrumb-item text-muted">
                            <a href="" class="text-muted text-hover-primary">Konto</a>
                        </li>
                        <!--end::Item-->
                        <!--begin::Item-->
                        <li class="breadcrumb-item">
                            <span class="bullet bg-gray-400 w-5px h-2px"></span>
                        </li>
                        <!--end::Item-->
                        <li class="breadcrumb-item text-muted">
                            <a href="" class="text-muted text-hover-primary">Moje zamówienia</a>
                        </li>
                        <li class="breadcrumb-item">
                            <span class="bullet bg-gray-400 w-5px h-2px"></span>
                        </li>
                        <!--begin::Item-->
                        <li class="breadcrumb-item text-muted">Lista zamówień</li>
                        <!--end::Item-->
                    </ul>
                    <!--end::Breadcrumb-->
                </div>
                <!--end::Page title-->
            </div>
            <!--end::Toolbar container-->
        </div>
        <!--end::Toolbar-->



        <!--begin::Content-->
        <div id="kt_app_content" class="app-content flex-column-fluid">
            <!--begin::Content container-->
            <div id="kt_app_content_container" class="app-container container-xxl">



                <div class="form d-flex flex-column flex-lg-row fv-plugins-bootstrap5 fv-plugins-framework">











                <!-- BEGIN: LEFT -->
                <div class="flex-column flex-lg-row-auto w-100 w-lg-250px w-xxl-325px mb-8 mb-lg-0 me-lg-9 me-5">
                    <form v-on:submit.prevent="getOrders()">
                        <div class="card">
                            <div class="card-body">
                                <div class="mb-5">
                                    <label class="form-label">Typ</label>

                                    <select class="form-select form-select-solid" v-model="filters.status">
                                        <option value="">Wszystkie</option>
                                        <option value="pending">W trakcie</option>
                                        <option value="completed">Zakończone</option>
                                        <option value="canceled">Anulowane</option>
                                    </select>
                                </div>

                                <div class="separator separator-dashed my-8"></div>

                                <div class="mb-5">
                                    <label class="form-label">Kwota od</label>

                                    <input class="form-control form-control-solid" v-model="filters.amount.from" />
                                </div>

                                <div class="mb-5">
                                    <label class="form-label">Kwota do</label>

                                    <input class="form-control form-control-solid" v-model="filters.amount.to" />
                                </div>

                                <div class="separator separator-dashed my-8"></div>

                                <div class="mb-5">
                                    <label class="form-label">Data od</label>

                                    <input class="form-control form-control-solid" v-model="filters.date.from" />
                                </div>

                                <div class="mb-5">
                                    <label class="form-label">Data do</label>

                                    <input class="form-control form-control-solid" v-model="filters.date.to" />
                                </div>

                                <div class="mt-10 d-flex justify-content-end">
                                    <button class="btn btn-light me-3" type="reset" v-on:click.prevent="reset()">Wyczyść</button>
                                    <button class="btn btn-primary">Filtruj</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <!-- END: LEFT -->







                <div class="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">









                <!--begin::Products-->
                <div class="card card-flush">
                    <!--begin::Card body-->
                    <div class="card-body pt-0">

                        <table class="table align-middle table-row-dashed fs-6 gy-5">
                            <thead>
                                <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                                    <th class="min-w-100px">Kontrahent</th>
                                    <th class="min-w-100px">Kwota</th>
                                    <th class="min-w-100px">Status</th>
                                    <th class="min-w-100px">Data</th>
                                </tr>
                            </thead>
                            <tbody class="fw-semibold text-gray-600" v-if="orders.length">
                                <tr v-for="order in orders" v-bind:key="order.id">
                                    <td>
                                        <router-link class="text-gray-600 text-hover-primary" v-bind:to="{ name: 'AccountOrderShow', params: { id: order.id } }">{{ order.user.name }}</router-link>

                                        <span class="text-gray-400 fw-semibold d-block fs-7">{{ order.id }}</span>
                                    </td>
                                    <td>{{ order.amount }} zł</td>
                                    <td>
                                        <span class="badge badge-light-primary p-3">{{ dictionaries.statuses[order.status] ?? '-' }}</span>
                                    </td>
                                    <td>{{ new Date(order.created_at).toLocaleDateString() }}</td>
                                </tr>
                            </tbody>
                            <tbody class="fw-semibold text-gray-600" v-else>
                                <tr>
                                    <td class="text-center" colspan="4">Brak zamówień</td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                    <!--end::Card body-->
                </div>
                <!--end::Products-->


                </div>
            </div>



            </div>
            <!--end::Content container-->
        </div>
        <!--end::Content-->
    </div>
    <!--end::Content wrapper-->
</template>

<script>
    export default {
        data() {
            return {
                dictionaries: {
                    statuses: {
                        pending:   'W trakcie',
                        completed: 'Zakończone',
                        canceled:  'Anulowane'
                    }
                },

                filters: {
                    status: '',

                    amount: {
                        from: '',
                        to:   ''
                    },

                    date: {
                        from: '',
                        to:   ''
                    }
                },

                orders: []
            }
        },

        methods: {
            async getOrders() {
                document.body.setAttribute('data-kt-app-page-loading', 'on');

                try {
                    let queryParameters = '';

                    queryParameters += '?filters[status]='       + this.filters.status;
                    queryParameters += '&filters[amount][from]=' + this.filters.amount.from;
                    queryParameters += '&filters[amount][to]='   + this.filters.amount.to;
                    queryParameters += '&filters[date][from]='   + this.filters.date.from;
                    queryParameters += '&filters[date][to]='     + this.filters.date.to;

                    const response = await fetch(this.$API_HOST + '/v1/orders' + queryParameters, {
                        method: 'GET',
                        mode:   'cors',

                        headers: {
                            'Authorization': 'Bearer ' + window.localStorage.getItem('token')
                        }
                    });

                    if (response.status === 401) {
                        this.$router.push({ name: 'SignIn' });
                    }

                    this.orders = (await response.json()).data;

                    setTimeout(() => {
                        document.body.setAttribute('data-kt-app-page-loading', 'off');
                    }, 250);
                } catch (error) {
                }
            },

            reset() {
                this.filters.status = '';

                this.filters.amount.from = '';
                this.filters.amount.to   = '';

                this.filters.date.from = '';
                this.filters.date.to   = '';

                this.getOrders();
            }
        },

        created() {
            document.body.setAttribute('data-kt-app-page-loading', 'on');
        },

        async mounted() {
            this.getOrders();
        }
    }
</script>
