<template>
    <!--begin::Content wrapper-->
    <div class="d-flex flex-column flex-column-fluid">
        <!--begin::Toolbar-->
        <div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
            <!--begin::Toolbar container-->
            <div id="kt_app_toolbar_container" class="app-container container-xxl d-flex flex-stack">
                <!--begin::Page title-->
                <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <!--begin::Title-->
                    <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Użytkownicy</h1>
                    <!--end::Title-->
                    <!--begin::Breadcrumb-->
                    <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                        <!--begin::Item-->
                        <li class="breadcrumb-item text-muted">
                            <a href="" class="text-muted text-hover-primary">Ustawienia administratora</a>
                        </li>
                        <!--end::Item-->
                        <!--begin::Item-->
                        <li class="breadcrumb-item">
                            <span class="bullet bg-gray-400 w-5px h-2px"></span>
                        </li>
                        <!--end::Item-->
                        <li class="breadcrumb-item text-muted">
                            <a href="" class="text-muted text-hover-primary">Użytkownicy</a>
                        </li>
                        <li class="breadcrumb-item">
                            <span class="bullet bg-gray-400 w-5px h-2px"></span>
                        </li>
                        <!--begin::Item-->
                        <li class="breadcrumb-item text-muted">Edycja użytkownika</li>
                        <!--end::Item-->
                    </ul>
                    <!--end::Breadcrumb-->
                </div>
                <!--end::Page title-->
            </div>
            <!--end::Toolbar container-->
        </div>
        <!--end::Toolbar-->



        <!--begin::Content-->
        <div id="kt_app_content" class="app-content flex-column-fluid">
            <!--begin::Content container-->
            <div id="kt_app_content_container" class="app-container container-xxl">




<!--begin::Form-->
                                    <form class="form d-flex flex-column flex-lg-row requires-validation" v-on:submit.prevent="submit" novalidate>

                                        <!--begin::Main column-->
                                        <div class="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">

                                            <!--begin::Tab content-->

                                                <!--begin::Tab pane-->


                                                        <!--begin::General options-->
                                                        <div class="card card-flush py-4">
                                                            <!--begin::Card header-->
                                                            <div class="card-header">
                                                                <div class="card-title">
                                                                    <h2>Aktualizacja profilu</h2>
                                                                </div>
                                                            </div>
                                                            <div class="card-body pt-0">
                                                                <div class="d-flex flex-wrap gap-5 mb-10">
                                                                    <div class="fv-row w-100 flex-md-root">
                                                                        <label class="required form-label">Nazwa firmy</label>

                                                                        <input class="form-control form-control-solid mb-2 border-0" type="text" v-model="user.name" required />
                                                                    </div>
                                                                    <div class="fv-row w-100 flex-md-root">
                                                                        <label class="required form-label">NIP</label>

                                                                        <input class="form-control form-control-solid mb-2 border-0" type="text" v-model="user.tax_number" required />
                                                                    </div>
                                                                </div>

                                                                <div class="d-flex flex-wrap gap-5 mb-10">
                                                                    <div class="fv-row w-100 flex-md-root">
                                                                        <label class="required form-label">Imię</label>

                                                                        <input class="form-control form-control-solid mb-2 border-0" type="text" v-model="user.first_name" required />
                                                                    </div>
                                                                    <div class="fv-row w-100 flex-md-root">
                                                                        <label class="required form-label">Nazwisko</label>

                                                                        <input class="form-control form-control-solid mb-2 border-0" type="text" v-model="user.last_name" required />
                                                                    </div>
                                                                </div>

                                                                <div class="d-flex flex-wrap gap-5 mb-10">
                                                                    <div class="fv-row w-100 flex-md-root">
                                                                        <label class="required form-label">Adres</label>

                                                                        <input class="form-control form-control-solid mb-2 border-0" type="text" v-model="user.address" required />
                                                                    </div>
                                                                    <div class="fv-row w-100 flex-md-root">
                                                                        <label class="required form-label">Kod pocztowy</label>

                                                                        <input class="form-control form-control-solid mb-2 border-0" type="text" v-model="user.postal_code" required />
                                                                    </div>
                                                                    <div class="fv-row w-100 flex-md-root">
                                                                        <label class="required form-label">Miasto</label>

                                                                        <input class="form-control form-control-solid mb-2 border-0" type="text" v-model="user.city" required />
                                                                    </div>
                                                                </div>

                                                                <div class="mb-10 fv-row">
                                                                    <label class="form-label">Numer telefonu</label>

                                                                    <input class="form-control form-control-solid mb-2 border-0" type="text" v-model="user.phone_number" />
                                                                </div>

                                                                <div class="mb-10 fv-row">
                                                                    <label class="form-label">Dodatkowy adres e-mail</label>

                                                                    <input class="form-control form-control-solid mb-2 border-0" type="email" v-model="user.email2" />
                                                                </div>

                                                                <div class="mb-10 fv-row">
                                                                    <label class="form-label">Numer konta</label>

                                                                    <input class="form-control form-control-solid mb-2 border-0" type="text" v-model="user.bank_account" />
                                                                </div>

                                                                <div class="mb-10 fv-row">
                                                                    <label class="form-label required">Aktywny?</label>

                                                                    <select class="form-select form-select-solid border-0" v-model="user.active" required>
                                                                        <option value="1">Tak</option>
                                                                        <option value="0">Nie</option>
                                                                    </select>
                                                                </div>


                                                            </div>
                                                        </div>
                                                        <!--end::General options-->




                                                <!--end::Tab pane-->

                                            <!--end::Tab content-->
                                            <div class="d-flex justify-content-end">
                                                <router-link class="btn btn-light me-5" v-bind:to="{ name: 'AdministrationUserList' }">Wróć</router-link>

                                                <button class="btn btn-primary" type="submit">
                                                    <span class="indicator-label">Zapisz</span>
                                                </button>
                                            </div>
                                        </div>
                                        <!--end::Main column-->
                                    </form>
                                    <!--end::Form-->




            </div>
            <!--end::Content container-->
        </div>
        <!--end::Content-->
    </div>
    <!--end::Content wrapper-->
</template>

<script>
    export default {
        data() {
            return {
                user: {}
            }
        },

        async mounted() {
            document.body.setAttribute('data-kt-app-page-loading', 'on');

            try {
                const response = await fetch(this.$API_HOST + '/v1/administration/users/' + this.$route.params.id, {
                    method: 'GET',
                    mode:   'cors',

                    headers: {
                        'Authorization': 'Bearer ' + window.localStorage.getItem('token')
                    }
                });

                if (response.status === 401) {
                    this.$router.push({ name: 'SignIn' });
                }

                this.user = (await response.json()).data;

                setTimeout(() => {
                    document.body.setAttribute('data-kt-app-page-loading', 'off');
                }, 250);
            } catch (error) {
            }
        },

        methods: {
            async submit(event) {
                if (!event.target.checkValidity()) {
                    event.target.classList.add('was-validated');

                    return;
                }

                document.body.setAttribute('data-kt-app-page-loading', 'on');

                try {
                    const response = await fetch(this.$API_HOST + '/v1/administration/users/' + this.$route.params.id, {
                        method: 'PATCH',
                        mode:   'cors',

                        headers: {
                            'Authorization': 'Bearer ' + window.localStorage.getItem('token'),
                            'Content-Type':  'application/json'
                        },

                        body: JSON.stringify(this.user)
                    });

                    if (response.status === 401) {
                        this.$router.push({ name: 'SignIn' });
                    }

                    setTimeout(() => {
                        document.body.setAttribute('data-kt-app-page-loading', 'off');
                    }, 250);
                } catch (error) {
                }
            }
        }
    }
</script>
