<template>
    <!--begin::Content wrapper-->
    <div class="d-flex flex-column flex-column-fluid">

        <!--begin::Toolbar-->
        <div class="app-toolbar py-3 py-lg-6">
            <div class="app-container container-xxl d-flex flex-stack">
                <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Produkty</h1>

                    <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">

                        <li class="breadcrumb-item text-muted">
                            <a href="" class="text-muted text-hover-primary">Produkty</a>
                        </li>
                        <li class="breadcrumb-item">
                            <span class="bullet bg-gray-400 w-5px h-2px"></span>
                        </li>

                        <li class="breadcrumb-item text-muted">Nowy produkt</li>
                    </ul>
                </div>
            </div>
        </div>
        <!--end::Toolbar-->



        <!--begin::Content-->
                            <div class="app-content flex-column-fluid">
                                <!--begin::Content container-->
                                <div class="app-container container-xxl">



                                    <!--begin::Form-->
                                    <form class="form d-flex flex-column flex-lg-row requires-validation" v-on:submit.prevent="submit" novalidate>

                                        <!--begin::Main column-->
                                        <div class="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">

                                            <!--begin::Tab content-->

                                                <!--begin::Tab pane-->

                                                    <div class="d-flex flex-column gap-7 gap-lg-10">
                                                        <!--begin::General options-->
                                                        <div class="card card-flush py-4">
                                                            <!--begin::Card header-->
                                                            <div class="card-header">
                                                                <div class="card-title">
                                                                    <h2>Informacje podstawowe</h2>
                                                                </div>
                                                            </div>
                                                            <div class="card-body pt-0">
                                                                <div class="mb-10 fv-row">
                                                                    <!--begin::Label-->
                                                                    <label class="required form-label">Nazwa</label>
                                                                    <!--end::Label-->
                                                                    <!--begin::Input-->
                                                                    <input class="form-control form-control-solid border-0" name="name" type="text" v-model="name" required />
                                                                    <!--end::Input-->
                                                                </div>
                                                                <div class="mb-10 fv-row">
                                                                    <!--begin::Label-->
                                                                    <label class="required form-label">Opis</label>
                                                                    <!--end::Label-->
                                                                    <!--begin::Editor-->
                                                                    <textarea class="form-control form-control-solid border-0" name="description" rows="10" type="text" v-model="description" required></textarea>
                                                                    <!--end::Editor-->
                                                                </div>










                                                                <div class="mb-10 fv-row">
                                                                    <!--begin::Label-->
                                                                    <label id="vinLabel" class="required form-label">VIN</label>
                                                                    <!--end::Label-->
                                                                    <!--begin::Input-->
                                                                    <input type="text" id="vin" name="number" class="form-control form-control-solid border-0" v-model="vin" required />
                                                                    <!--end::Input-->
                                                                </div>




<!--begin::Tax-->
                                <div class="d-flex flex-wrap gap-5 mb-10">
                                    <!--begin::Input group-->
                                    <div class="fv-row w-100 flex-md-root">
                                        <!--begin::Label-->
                                        <label class="required form-label">Stan</label>
                                        <!--end::Label-->
                                        <!--begin::Select2-->
                                        <select class="form-select form-select-solid border-0" v-model="state" required>
                                            <option value="new">Nowy</option>
                                            <option value="used">Używany</option>
                                            <option value="used_damaged">Używany (uszkodzony)</option>
                                        </select>
                                        <!--end::Select2-->
                                    </div>
                                    <!--end::Input group-->
                                    <!--begin::Input group-->
                                    <div class="fv-row w-100 flex-md-root">
                                        <!--begin::Label-->
                                        <label class="required form-label">Typ</label>
                                        <!--end::Label-->
                                        <!--begin::Select2-->
                                        <select class="form-select form-select-solid border-0" v-model="type" required>
                                            <option value="original">Oryginał</option>
                                            <option value="replacement">Zamiennik</option>
                                        </select>
                                        <!--end::Select2-->

                                    </div>
                                    <!--end::Input group-->

                                </div>
                                <!--end:Tax-->



                    <div class="d-flex flex-wrap gap-5 mb-10 d-none" id="damages">
                    <!--begin::Col-->
                        <!--begin::Options-->
                        <div class="d-flex align-items-center gap-15">
                            <!--begin::Option-->
                            <label class="form-check form-check-custom form-check-inline form-check-solid me-5">
                                <input class="form-check-input" name="damages[]" type="checkbox" value="1">
                                <span class="fw-semibold ps-2 fs-6">
                                    Rysa
                                </span>
                            </label>
                            <!--end::Option-->

                            <!--begin::Option-->
                            <label class="form-check form-check-custom form-check-inline form-check-solid">
                                <input class="form-check-input" name="damages[]" type="checkbox" value="2">
                                <span class="fw-semibold ps-2 fs-6">
                                    Wgniecenie
                                </span>
                            </label>
                            <!--end::Option-->

                            <!--begin::Option-->
                            <label class="form-check form-check-custom form-check-inline form-check-solid">
                                <input class="form-check-input" name="damages[]" type="checkbox" value="2">
                                <span class="fw-semibold ps-2 fs-6">
                                    Pękniecie
                                </span>
                            </label>
                            <!--end::Option-->
                        </div>
                        <!--end::Options-->

                    <!--end::Col-->
                </div>




<!--begin::Tax-->
                                <div class="d-flex flex-wrap gap-5 mb-10">
                                    <!--begin::Input group-->
                                    <div class="fv-row w-100 flex-md-root">
                                        <!--begin::Label-->
                                        <label class="form-label">Marka</label>
                                        <!--end::Label-->
                                        <!--begin::Select2-->
                                        <input type="text" class="form-control form-control-solid border-0" v-model="brand" />
                                        <!--end::Select2-->
                                    </div>
                                    <!--end::Input group-->
                                    <!--begin::Input group-->
                                    <div class="fv-row w-100 flex-md-root">
                                        <!--begin::Label-->
                                        <label class="form-label">Model</label>
                                        <!--end::Label-->
                                        <!--begin::Select2-->
                                        <input type="text" class="form-control form-control-solid border-0" v-model="make" />
                                        <!--end::Select2-->

                                    </div>
                                    <!--end::Input group-->

                                </div>
                                <!--end:Tax-->









                                                            </div>
                                                        </div>
                                                        <!--end::General options-->


                                    <!-- begin::Prices -->
                                    <div class="card card-flush py-4">
                                        <div class="card-header">
                                            <div class="card-title">
                                                <h2>Cena</h2>
                                            </div>
                                        </div>

                                        <div class="card-body pt-0">
                                            <div class="d-flex flex-wrap gap-5">
                                                <div class="fv-row w-100 flex-md-root">
                                                    <label class="form-label required">Cena produktu</label>

                                                    <input type="number" class="form-control form-control-solid border-0" v-model.number="price" min="1" step="0.01" required />

                                                    <div class="text-muted fs-7 mt-2">Cena netto produktu</div>
                                                </div>

                                                <div class="fv-row w-100 flex-md-root">
                                                    <label class="form-label required">Koszt dostawy</label>

                                                    <input type="number" class="form-control form-control-solid border-0" v-model.number="delivery_price" min="0" step="0.01" required />

                                                    <div class="text-muted fs-7 mt-2">Koszt netto dostawy</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- end::Prices -->

                                    <!-- begin::Codes -->
                                    <div class="card card-flush py-4">
                                        <div class="card-header">
                                            <div class="card-title">
                                                <h2>Kody</h2>
                                            </div>
                                        </div>

                                        <div class="card-body pt-0">
                                            <div class="d-flex flex-wrap gap-5">
                                                <div class="fv-row w-100 flex-md-root">
                                                    <label class="form-label">Kod OE</label>

                                                    <input type="text" class="form-control form-control-solid border-0" v-model="oe" />
                                                </div>

                                                <div class="fv-row w-100 flex-md-root">
                                                    <label class="form-label">Kod OEM</label>

                                                    <input type="text" class="form-control form-control-solid border-0" v-model="oem" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- end::Codes -->











                                                        <!--begin::Media-->
                                                        <div class="card card-flush py-4">
                                                            <!--begin::Card header-->
                                                            <div class="card-header">
                                                                <div class="card-title">
                                                                    <h2>Zdjęcia</h2>
                                                                </div>
                                                            </div>
                                                            <!--end::Card header-->
                                                            <!--begin::Card body-->
                                                            <div class="card-body pt-0">
                                                                <!--begin::Input group-->
                                                                <div class="fv-row mb-2">
                                                                    <!--begin::Dropzone-->
                                                                    <div class="dropzone">
                                                                        <!--begin::Message-->
                                                                        <div class="dz-message needsclick">
                                                                            <!--begin::Icon-->
                                                                            <i class="bi bi-file-earmark-arrow-up text-primary fs-3x"></i>
                                                                            <!--end::Icon-->
                                                                            <!--begin::Info-->
                                                                            <div class="ms-4">
                                                                                <h3 class="fs-5 fw-bold text-gray-900 mb-1">Przenieś pliki tutaj lub kliknij aby dodać zdjęcia</h3>
                                                                                <span class="fs-7 fw-semibold text-gray-400">Maksymalny rozmiar pojedynczego pliku to 5MB</span>
                                                                            </div>
                                                                            <!--end::Info-->
                                                                        </div>
                                                                    </div>
                                                                    <!--end::Dropzone-->
                                                                </div>
                                                                <!--end::Input group-->
                                                            </div>
                                                            <!--end::Card header-->
                                                        </div>
                                                        <!--end::Media-->


                                                    </div>

                                                <!--end::Tab pane-->

                                            <!--end::Tab content-->
                                            <div class="d-flex justify-content-end">
                                                <router-link class="btn btn-light me-5" v-bind:to="{ name: 'PartList' }">Anuluj</router-link>

                                                <button class="btn btn-primary" type="submit">
                                                    <span class="indicator-label">Dodaj</span>
                                                </button>
                                            </div>
                                        </div>
                                        <!--end::Main column-->
                                    </form>
                                    <!--end::Form-->



                                </div>
                                <!--end::Content container-->
                            </div>
                            <!--end::Content-->



    </div>
    <!--end::Content wrapper-->
</template>

<script>
    export default {
        data() {
            return {
                state:       '',
                type:        '',
                name:        '',
                description: '',
                vin:         '',
                price:       '',
                price_gross: '',
                brand:       '',
                make:        '',
                oe:          '',
                oem:         '',

                dropzone: null,
            }
        },

        mounted() {
            const router = this.$router;

            // Remove existing Dropzone elements
            document.querySelectorAll('input.dz-hidden-input').forEach(function (element) {
                element.parentNode.removeChild(element)
            });

            this.dropzone = new Dropzone('.dropzone', {
                url: '/',

                autoProcessQueue: false,
                addRemoveLinks: true,

                acceptedFiles: 'image/*',

                maxFilesize: 5,
                maxFiles :   10,

                headers: {
                    'Authorization': 'Bearer ' + window.localStorage.getItem('token')
                },

                init: function() {
                    this.on('processing', function() {
                        this.options.autoProcessQueue = true;
                    });

                    this.on('success', function() {
                        router.push({name: 'AccountPartList' });
                    });
                }
            });
        },

        watch: {
            // price(n) {
            //     if (Number.isInteger(n)) {
            //         this.price_gross = n * 1.23;
            //     }
            // },

            // price_gross(n) {
            //     if (Number.isInteger(n)) {
            //         this.price = n / 1.23;
            //     }
            // },

            state(v) {
                if (v === 'new') {
                    document.getElementById('vin').required = false;
                    document.getElementById('vinLabel').classList.remove('required');
                }
                else {
                    document.getElementById('vin').required = true;
                    document.getElementById('vinLabel').classList.add('required');
                }

                if (v === 'used_damaged') {
                    document.getElementById('damages').classList.remove('d-none');
                } else {
                    document.getElementById('damages').classList.add('d-none');
                }
            }
        },

        methods: {
            async submit(event) {
                if (!event.target.checkValidity()) {
                    event.target.classList.add('was-validated');

                    return;
                }

                try {
                    let codes = [];

                    if (this.oe) {
                        codes.push({
                            'type': 'oe',
                            'value': this.oe
                        });
                    }

                    if (this.oem) {
                        codes.push({
                            'type': 'oem',
                            'value': this.oem
                        });
                    }

                    const response = await fetch(this.$API_HOST + '/v1/products', {
                        method: 'POST',
                        mode:   'cors',

                        headers: {
                            'Authorization': 'Bearer ' + window.localStorage.getItem('token'),
                            'Content-Type':  'application/json'
                        },

                        body: JSON.stringify({
                            'status':         'published',
                            'state':          this.state,
                            'type':           this.type,
                            'name':           this.name,
                            'description':    this.description,
                            'vin':            this.vin,
                            'price':          this.price,
                            'delivery_price': this.delivery_price,
                            'brand':          this.brand,
                            'make':           this.make,
                            'codes':          codes
                        })
                    });

                    if (response.status === 401) {
                        this.$router.push({ name: 'SignIn' });
                    }

                    if (this.dropzone.getQueuedFiles().length) {
                        this.dropzone.options.url = this.$API_HOST + '/v1/products/' + (await response.json()).data.id + '/attachments';

                        this.dropzone.processQueue();
                    } else {
                        this.$router.push({ name: 'AccountPartList' });
                    }
                } catch (error) {
                }
            }
        }
    }
</script>
