<template>
    <!--begin::Content wrapper-->
    <div class="d-flex flex-column flex-column-fluid">
        <!--begin::Toolbar-->
        <div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
            <!--begin::Toolbar container-->
            <div id="kt_app_toolbar_container" class="app-container container-xxl d-flex flex-stack">
                <!--begin::Page title-->
                <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <!--begin::Title-->
                    <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Produkty</h1>
                    <!--end::Title-->
                    <!--begin::Breadcrumb-->
                    <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                        <!--begin::Item-->
                        <li class="breadcrumb-item text-muted">
                            <a href="" class="text-muted text-hover-primary">Produkty</a>
                        </li>
                        <!--end::Item-->
                        <!--begin::Item-->
                        <li class="breadcrumb-item">
                            <span class="bullet bg-gray-400 w-5px h-2px"></span>
                        </li>
                        <!--end::Item-->
                        <!--begin::Item-->
                        <li class="breadcrumb-item text-muted">Szczegóły produktu</li>
                        <!--end::Item-->
                    </ul>
                    <!--end::Breadcrumb-->
                </div>
                <!--end::Page title-->
                <!--begin::Actions-->
                <div class="d-flex align-items-center gap-2 gap-lg-3">
                    <!--begin::Primary button-->
                    <button class="btn btn-sm fw-bold btn-primary" v-if="canReserve(product)" v-on:click="reserve()">Dodaj do koszyka</button>
                    <!--end::Primary button-->
                </div>
                <!--end::Actions-->
            </div>
            <!--end::Toolbar container-->
        </div>
        <!--end::Toolbar-->
        <!--begin::Content-->
        <div id="kt_app_content" class="app-content flex-column-fluid">
            <!--begin::Content container-->
            <div id="kt_app_content_container" class="app-container container-xxl">




		<FsLightbox
			:toggler="toggler"
			:sources="sources" />





                <div class="card mb-6 mb-xl-9">
                    <div class="card-body pt-9 pb-0">
                        <!--begin::Details-->
                        <div class="d-flex flex-wrap flex-sm-nowrap mb-6">
                            <div class="me-7 mb-4">






                                <div class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                                    <img @click="toggler = !toggler" :src="getImagePath(product)" class="border border-2">
                                </div>
                            </div>

                            <!--end::Image-->
                            <!--begin::Wrapper-->
                            <div class="flex-grow-1">
                                <!--begin::Head-->
                                <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                    <!--begin::Details-->
                                    <div class="d-flex flex-column">
                                        <!--begin::Status-->
                                        <div class="d-flex align-items-center mb-1">
                                            <a href="#" class="text-gray-800 text-hover-primary fs-2 fw-bold me-3 text-uppercase">{{ product.name }}</a>
                                        </div>
                                        <!--end::Status-->
                                        <!--begin::Description-->
                                        <div class="d-flex flex-wrap fw-semibold mb-4 fs-5 text-gray-400">{{ new Date(product.created_at).toLocaleDateString() }}</div>
                                        <!--end::Description-->
                                    </div>
                                    <!--end::Details-->
                                    <!--begin::Actions-->

                                    <!--end::Actions-->
                                </div>
                                <!--end::Head-->
                                <!--begin::Info-->
                                <div class="d-flex flex-wrap justify-content-start">
                                    <!--begin::Stats-->
                                    <div class="d-flex flex-wrap">
                                        <!--begin::Stat-->
                                        <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                            <!--begin::Number-->
                                            <div class="fw-semibold fs-6 text-gray-400">Cena</div>

                                            <div class="d-flex align-items-center">
                                                <div class="fs-4 fw-bold">{{ product.price }} zł</div>
                                            </div>
                                            <!--end::Number-->
                                            <!--begin::Label-->

                                            <!--end::Label-->
                                        </div>
                                        <!--end::Stat-->

                                        <!--begin::Stat-->
                                        <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                            <!--begin::Number-->
                                            <div class="fw-semibold fs-6 text-gray-400">VIN</div>

                                            <div class="d-flex align-items-center">
                                                <div class="fs-4 fw-bold">{{ product.vin || '-' }}</div>
                                            </div>
                                            <!--end::Number-->
                                            <!--begin::Label-->

                                            <!--end::Label-->
                                        </div>
                                        <!--end::Stat-->

                                        <!--begin::Stat-->
                                        <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                            <!--begin::Number-->
                                            <div class="fw-semibold fs-6 text-gray-400">Typ</div>

                                            <div class="d-flex align-items-center">
                                                <div class="fs-4 fw-bold">{{ dictionaries.types[product.type] ?? '-' }}</div>
                                            </div>
                                            <!--end::Number-->
                                            <!--begin::Label-->

                                            <!--end::Label-->
                                        </div>
                                        <!--end::Stat-->
                                        <!--begin::Stat-->
                                        <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                            <!--begin::Number-->
                                            <div class="fw-semibold fs-6 text-gray-400">Stan</div>

                                            <div class="d-flex align-items-center">
                                                <div class="fs-4 fw-bold">{{ dictionaries.states[product.state] ?? '-' }}</div>
                                            </div>
                                            <!--end::Number-->
                                            <!--begin::Label-->

                                            <!--end::Label-->
                                        </div>
                                        <!--end::Stat-->
                                        <!--begin::Stat-->
                                        <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                            <!--begin::Number-->
                                            <div class="fw-semibold fs-6 text-gray-400">Marka</div>

                                            <div class="d-flex align-items-center">
                                                <div class="fs-4 fw-bold">{{ product.brand || '-' }}</div>
                                            </div>
                                            <!--end::Number-->
                                            <!--begin::Label-->

                                            <!--end::Label-->
                                        </div>
                                        <!--end::Stat-->
                                        <!--begin::Stat-->
                                        <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                            <!--begin::Number-->
                                            <div class="fw-semibold fs-6 text-gray-400">Model</div>

                                            <div class="d-flex align-items-center">
                                                <div class="fs-4 fw-bold">{{ product.make || '-' }}</div>
                                            </div>
                                            <!--end::Number-->
                                            <!--begin::Label-->

                                            <!--end::Label-->
                                        </div>
                                        <!--end::Stat-->

                                    </div>
                                    <!--end::Stats-->
                                    <!--begin::Users-->

                                    <!--end::Users-->
                                </div>
                                <!--end::Info-->
                            </div>
                            <!--end::Wrapper-->
                        </div>
                        <!--end::Details-->
                        <!--begin::Nav-->

                        <!--end::Nav-->
                    </div>
                </div>

                        <div class="card pt-4 mb-6 mb-xl-9" v-if="1">
                                <!--begin::Card header-->
                                <div class="card-header border-0">
                                    <!--begin::Card title-->
                                    <div class="card-title">
                                        <h2>Kody</h2>
                                    </div>
                                    <!--end::Card title-->
                                </div>
                                <!--end::Card header-->
                                <!--begin::Card body-->
                                <div class="card-body pt-0 pb-5">
                                    <!--begin::Table wrapper-->
                                        <!--begin::Table-->
                                        <table class="table align-middle table-row-dashed gy-5" id="kt_table_users_login_session">
                                            <!--begin::Table body-->
                                            <tbody class="fs-6 fw-semibold text-gray-600">
                                                <tr v-for="code in product.codes" v-bind:key="code.id">
                                                    <td>{{ code.type.toUpperCase() }}</td>
                                                    <td>{{ code.value.toUpperCase() }}</td>
                                                </tr>
                                            </tbody>
                                            <!--end::Table body-->
                                        </table>
                                        <!--end::Table-->
                                    <!--end::Table wrapper-->
                                </div>
                                <!--end::Card body-->
                            </div>

                <div class="card card-flush py-4">
                                        <!--begin::Card header-->
                                        <div class="card-header">
                                            <div class="card-title">
                                                <h2>Opis</h2>
                                            </div>
                                        </div>
                                        <!--end::Card header-->
                                        <!--begin::Card body-->
                                        <div class="card-body pt-0">
                                            <!--begin::Input group-->
                                            <div class="fv-row mb-2" style="white-space: pre-wrap;">
                                                {{ product.description }}
                                            </div>
                                            <!--end::Input group-->
                                        </div>
                                        <!--end::Card header-->
                </div>











            </div>
            <!--end::Content container-->
        </div>
        <!--end::Content-->
    </div>
    <!--end::Content wrapper-->


</template>

<script>
    //import '../../../public/assets/plugins/custom/fslightbox/fslightbox.bundle.js';
    import FsLightbox from "fslightbox-vue/v3"

    export default {
        components: { FsLightbox },

        data() {
            return {
                toggler: false,
                sources: [],

                product: {},

                dictionaries: {
                    states: {
                        new:     'Nowy',
                        used:    'Używany',
                        ordered: 'Kupiony'
                    },

                    types: {
                        original:    'Oryginał',
                        replacement: 'Zamiennik'
                    }
                }
            }
        },

        async mounted() {
            try {
                document.body.setAttribute('data-kt-app-page-loading', 'on');

                const response = await fetch(this.$API_HOST + '/v1/products/' + this.$route.params.id, {
                    method: 'GET',
                    mode:   'cors',

                    headers: {
                        'Authorization': 'Bearer ' + window.localStorage.getItem('token')
                    }
                });

                if (response.status === 401) {
                    this.$router.push({ name: 'SignIn' });
                }

                this.product = (await response.json()).data;

                this.product.attachments.forEach(attachment => {
                    this.sources.push(this.$ASSETS_HOST + '/' + attachment.path);
                });

                setTimeout(() => {
                    document.body.setAttribute('data-kt-app-page-loading', 'off');
                }, 250);
            } catch (error) {
            }
        },

        methods: {
            async reserve() {
                try {
                    const response = await fetch(this.$API_HOST + '/v1/reservations', {
                        method: 'POST',
                        mode:   'cors',

                        headers: {
                            'Authorization': 'Bearer ' + window.localStorage.getItem('token'),
                            'Content-Type':  'application/json'
                        },

                        body: JSON.stringify({
                            'product_id': this.$route.params.id,
                        })
                    });

                    if (response.status === 401) {
                        this.$router.push({ name: 'SignIn' });
                    }

                    this.$router.push({ name: 'CartList' }); // @todo Cart -> Reservations
                } catch (error) {
                }
            },

            getImagePath(product) {
                if (product.id) {
                    if (product.attachments.length > 0) {
                        return this.$ASSETS_HOST + '/' + product.attachments[0].path;
                    }
                }

                return '/assets/media/icons/blank.svg';
            },

            canReserve(product) {
                const user = JSON.parse(
                    window.localStorage.getItem('user')
                );

                return product.status == 'published' && product.user_id != user.id;
            }
        }
    }
</script>
